<template>
  <div
    id="ukg-mapping-edit-modal"
    ref="ukg-mapping-edit-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            {{ modalTitle }}
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="item.ukgBrandName"
              :errors="v$.item.ukgBrandName.$errors"
              description="Brand name in UKG"
              label="UKG Brand name"
              :readonly="item.id"
              @blur="v$.item.ukgBrandName.$touch"
            />
            <TextInput
              v-model="item.deposcoBrandName"
              :errors="v$.item.deposcoBrandName.$errors"
              description="Brand name in Deposco"
              label="Deposco Brand name"
              @blur="v$.item.deposcoBrandName.$touch"
            />
            <TextInput
              v-model="item.deposcoCompanyCode"
              :errors="v$.item.deposcoCompanyCode.$errors"
              description="Company code in Deposco"
              label="Deposco company code"
              @blur="v$.item.deposcoCompanyCode.$touch"
            />
            <DatePicker
              v-model="item.uploadedDate"
              :errors="v$.item.uploadedDate.$errors"
              :formatter="dateFormatter"
              description="The mapping rule will be applied starting from this date"
              label="Uploaded date"
              @blur="v$.item.uploadedDate.$touch"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              :disabled="isDeleteButtonDisabled"
              class="btn btn-danger"
              @click="showDeleteConfirmationModalHandler"
            >
              Delete
            </button>
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="ukg-mapping-edit-confirmation-modal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The changes you have just made will only
          apply to all new data. Not historical data.
        </h6>
      </div>
    </template>
  </ConfirmationModal>
  <DeleteConfirmationModal
    ref="ukg-mapping-delete-confirmation-modal"
    :onCancelHandler="cancelDeleteHandler"
    :onConfirmHandler="deleteRecordHandler"
  />
</template>
<script>
import ConfirmationModal from '@/components/common/ConfirmationModal';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal';
import TextInput from '@/components/common/TextInput';
import DatePicker from '@/components/common/DatePicker';
import Spinner from '@/components/common/Spinner';
import { mapActions } from 'vuex';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { Modal } from 'bootstrap';
import { dateFormatter, modalViewTypes } from '@/components/constants';

const defaultItem = {
  jobcode: null,
  costCenter: null,
  function: null,
  uploadedDate: null
};

export default {
  components: {
    ConfirmationModal,
    DeleteConfirmationModal,
    TextInput,
    Spinner,
    DatePicker
  },
  props: [ 'viewType', 'brand', 'brandCode' ],
  emits: ['successSave'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      editModal: null,
      confirmationModalSave: null,
      confirmationModalDelete: null,
      initialized: false,
      item: {
        id: null,
        ukgBrandName: null,
        deposcoBrandName: null,
        deposcoCompanyCode: null,
        uploadedDate: null,
      }
    };
  },
  validations() {
    return {
      item: {
        ukgBrandName: { required },
        deposcoBrandName: { required },
        deposcoCompanyCode: { required },
        uploadedDate: { required },
      }
    };
  },
  computed: {
    modalTitle() {
      return this.viewType === modalViewTypes.edit ? 'Edit row' : 'Add New';
    },
    isSaveButtonDisabled() {
      return !this.initialized;
    },
    isDeleteButtonDisabled() {
      return !this.initialized || this.viewType === modalViewTypes.add;
    }
  },
  async mounted() {
    this.editModal = new Modal(this.$refs['ukg-mapping-edit-modal']);
    this.confirmationModalSave = this.$refs['ukg-mapping-edit-confirmation-modal'].confirmationModal;
    this.confirmationModalDelete = this.$refs['ukg-mapping-delete-confirmation-modal'].confirmationModal;
    this.confirmationModalSave.hide();
    this.confirmationModalDelete.hide();
  },
  methods: {
    ...mapActions({
      fetchUKGMappingItems: 'ukgMapping/fetchUKGMappingItems',
      fetchUKGMappingItemById: 'ukgMapping/fetchUKGMappingItemById',
      createUKGMappingItem: 'ukgMapping/createItem',
      updateUKGMappingItem: 'ukgMapping/updateItem',
      deleteUKGMappingItem: 'ukgMapping/deleteItem'
    }),
    async initModal(itemId) {
      this.initialized = false;
      this.editModal.show();
      const itemToEdit = itemId ? await this.fetchUKGMappingItemById(itemId) : { ...defaultItem };
      if (!itemId && this.brand && this.brandCode) {
        itemToEdit.deposcoBrandName = this.brand;
        itemToEdit.deposcoCompanyCode = this.brandCode;
      }
      this.updateItemData(itemToEdit);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.item.id = newValue.id;
      this.item.ukgBrandName = newValue.ukgBrandName;
      this.item.deposcoBrandName = newValue.deposcoBrandName;
      this.item.deposcoCompanyCode = newValue.deposcoCompanyCode;
      this.item.uploadedDate = newValue.uploadedDate;
    },
    showDeleteConfirmationModalHandler() {
      this.editModal.hide();
      this.confirmationModalDelete.show();
    },
    cancelDeleteHandler() {
      this.confirmationModalDelete.hide();
      this.editModal.show();
    },
    async deleteRecordHandler() {
      try {
        await this.deleteUKGMappingItem(this.item.id);
        await this.fetchUKGMappingItems();
      } catch (e) {
        this.$toast.error('Failed to delete item.');
      }
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.editModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.item
      };
      try {
        if (dataToSave.id) {
          await this.updateUKGMappingItem(dataToSave);
          this.$toast.success('Item successfully updated');
        } else {
          await this.createUKGMappingItem(dataToSave);
          this.$toast.success('Item successfully created');
        }
        await this.fetchUKGMappingItems();
        this.$emit('successSave');
      } catch (e) {
        const message = e.message ? `: ${e.message}` : '';
        this.$toast.error(`Failed to save the data${message}.`);
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
    dateFormatter(date) {
      return dateFormatter(date);
    },
  }
};
</script>
